import { defineStore } from 'pinia'
import fastDeepEqual from 'fast-deep-equal'

import Dictionary from '~/types/Dictionary'

import { SearchType } from '~/components/search/enums/SearchType'

interface SearchUiState {
  configure?: {},
  numericMenu?: {},
  refinementList?: {},
  sortBy?: string,
  query?: string,
}

interface SearchState {
  dictionary: Dictionary,
  hiddenFacets: string[],
  isReady: boolean,
  searchType: SearchType,
  uiState: SearchUiState,
}

const unwrap = (vars : any) => JSON.parse(JSON.stringify(vars))

export const useSearchStore = defineStore('search', {
  state: (): SearchState => ({
    dictionary: {} as Dictionary,
    hiddenFacets: [],
    isReady: false,
    searchType: SearchType.SITE,
    uiState: {} as SearchUiState,
    query: '',
  }),
  actions: {
    dictionaryLookup(key: string): string {
      return this.dictionary[key] || key
    },
    setDictionary(dictionary: Dictionary) {
      this.dictionary = dictionary
    },
    setHiddenFacets(facets: []) {
      this.hiddenFacets = facets
    },
    setSearchType(searchType: SearchType) {
      this.searchType = searchType
    },
    setAisState(uiState: {}) {
      if (!fastDeepEqual(this.uiState, uiState)) {
        this.uiState = uiState
      }
      this.query = uiState.query || ''
    },
    setIsReady(isReady: boolean) {
      this.isReady = isReady
    },
  },
})
