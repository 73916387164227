<template>
  <button
    type="button"
    class="link link--blue-dark relative"
    data-test-id="cart-link-button"
    @click="handleClick"
  >
    <span
      v-if="cartCount > 0"
      class="absolute text-sm text-white bg-orange-500 rounded-full h-5 w-5 p-2 -right-1/2 -top-1/2 flex items-center justify-center"
      v-text="cartCount"
    />
    <span
      class="sr-only"
      v-text="'View Cart'"
    />
    <SvgIcon
      aria-hidden="true"
      icon-name="cart-shopping"
      height="24"
      width="27"
    />
  </button>
</template>

<script lang="ts">
export default { name: 'CartLink' }
</script>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import SvgIcon from '../global/SvgIcon.vue'

import { useUserStore } from '~/store/modules/user'

const userStore = useUserStore()
const { cartCount } = storeToRefs(userStore)

const handleClick = () => {
  window.location.assign(`${import.meta.env.VITE_ACCOUNT_SUBDOMAIN}/cart`)
}
</script>
