<template>
  <Disclosure
    v-slot="{ open }: { open: boolean }"
    as="div"
    class="relative border-t last:border-b border-gray-blue-100"
    :default-open="defaultOpen ? true : false"
  >
    <DisclosureButton 
      class="bg-white sticky top-0 z-10 w-full px-2.5 py-6 text-blue-dark-900 sc-focus flex items-center justify-between w-full"
      data-test-id="accordion-trigger"
    >
      <h2 class="text-3xl font-bold text-left">
        {{ heading }}
      </h2>

      <SvgIcon
        v-if="open"
        aria-hidden="true"
        icon-name="minus"
        class="w-5 h-5 shrink-0"
      />
      <SvgIcon
        v-else
        aria-hidden="true"
        icon-name="plus"
        class="w-5 h-5 shrink-0"
      />
    </DisclosureButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="z-0 px-2.5 pb-8">
        <slot />
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script lang="ts">
export default { name: 'AccordionItem' }
</script>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

import SvgIcon from './SvgIcon.vue'

defineProps<{
  heading: string,
  defaultOpen: string | boolean,
}>()
</script>
