import { HydratableComponentFactory } from '~/hydrater/types'

/**
 * Base Adapter class for wrapping hydratable components onto DOM elements
 *
 * - Provides a standardised mounting/unmounting pattern
 * - Provides references to the component instance and the DOM element
 */
export default abstract class BaseHydratable {
  /**
   * The element that the component will be mounted on
   */
  public el : HTMLElement
  /**
   * The component instance
   */
  public component : any
  protected constructor(componentFactory: HydratableComponentFactory, el: HTMLElement, factoryOptions: object = {}) {
    this.component = componentFactory(el, factoryOptions)
    this.el = el
  }
  /**
   * Called when the component should be mounted, returns this to allow chaining
   */
  abstract mount(): BaseHydratable
  /**
   * Called when the component should be unmounted, returns this to allow chaining
   */
  abstract unmount(): BaseHydratable
}
