import { nextTick } from 'vue'
import { storeToRefs } from 'pinia'

import { useUiStore } from '~/store/modules/ui'

interface UseFlyoutMenu {
  toggleMenu: (event: MouseEvent | KeyboardEvent, menuName: string) => Promise<void>
}

const useFlyoutMenu = (): UseFlyoutMenu => {
  const toggleMenu = async (event: MouseEvent | KeyboardEvent, menuName: string): Promise<void> => {
    const uiStore = useUiStore()
    const { openNavDropdown } = storeToRefs(uiStore)

    await nextTick()

    // Headless UI's Popover component displays a focus indicator on click.
    // This is a workaround to prevent that from happening.
    if (event instanceof MouseEvent && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    uiStore.toggleNavDropdown(openNavDropdown.value !== menuName ? menuName : '')
  }

  return {
    toggleMenu,
  }
}

export default useFlyoutMenu
