import Toast from 'vue-toastification'
import VueCookies from 'vue-cookies'
import { createApp } from 'vue'
import {Pinia, storeToRefs} from 'pinia'

import useSentry from '~/composables/useSentry'
import components from '~/components'
import { useUiStore } from '~/store/modules/ui'
import SentryConfig from '~/types/SentryConfig'



interface AppConfig {
  mountName?: string
  CONFIG?: {
    SENTRY?: SentryConfig,
    URLS?: Record<string, string>,
  }
  FLAGS?: {
    withSentry?: boolean
  }
}

/**
 * A factory for Vue app instances to mount into the DOM
 *
 * Intended to be used with the Hydrater's Strategy pattern
 *
 * @param pinia
 * @param appConfig
 */
export function useApp(pinia: Pinia, appConfig: AppConfig) {
  const { CONFIG, FLAGS } = appConfig

  const app = createApp({
    name: appConfig.mountName || 'App',
    setup() {
      const uiStore = useUiStore()
      const {
        openNavDropdown,
        navWrapperClasses,
      } = storeToRefs(uiStore)

      uiStore.setUrls(CONFIG?.URLS || {})

      return {
        openNavDropdown,
        navWrapperClasses,
      }
    },
  })

  app.use(pinia)
  app.use(Toast)
  app.use(VueCookies)

  if (FLAGS?.withSentry && CONFIG?.SENTRY?.ENABLED) {
    useSentry(CONFIG.SENTRY, app)
  }

  components.forEach((Component) => {
    app.component(Component.name, Component)
  })

  return app
}
