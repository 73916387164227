<template>
  <PopoverButton
    v-slot="{ open }: { open: boolean }"
    :class="buttonClasses"
    data-test-id="desktop-flyout-menu-trigger"
    @click="handleClick"
    @keydown.enter="handleClick"
  >
    {{ text }}
    <SvgIcon
      aria-hidden="true"
      icon-name="chevron-down"
      class="w-5 h-4"
      :class="{ 'rotate-180': open }"
    />
  </PopoverButton>
</template>

<script lang="ts">
export default { name: 'DesktopFlyoutMenuTrigger' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { PopoverButton } from '@headlessui/vue'

import SvgIcon from '../global/SvgIcon.vue'

import { useUiStore } from '~/store/modules/ui'
import useFlyoutMenu from '~/composables/useFlyoutMenu'

const props = defineProps<{
  text: string
}>()

const uiStore = useUiStore()
const { mobileNavIsOpen } = storeToRefs(uiStore)

const { toggleMenu } = useFlyoutMenu()

const buttonClasses = computed(() => {
  return mobileNavIsOpen.value
    ? 'flex items-center justify-between w-full font-semibold text-blue-dark-900'
    : 'link link--blue-dark text-lg font-medium leading-6'
})

const handleClick = async (event: MouseEvent | KeyboardEvent) => {
  await toggleMenu(event, props.text)
}

// TODO: Figure out how to remove focus indicator on click outside
// TODO: Figure out how to run custom logic on close
</script>
