<template>
  <div class="pb-16 lg:pb-36 pt-2 cc-page-grid">
    <div class="flex items-end justify-between col-span-12 gap-4 pb-4 -mb-8 border-b lg:gap-8 max-lg:flex-col lg:col-start-4 lg:col-span-9 border-gray-blue-200">
      <slot name="summary" />
    </div>

    <aside class="min-h-[130px] col-span-12 lg:col-span-3 h-fit max-lg:order-first flex flex-col gap-9 bg-white">
      <a
        v-if="isFilteredByCategory"
        :href="url.link"
        class="btn btn--md btn--primary capitalize"
      >
        {{ url.text }}
      </a>
      <div class="border shadow-sm border-gray-blue-200 p-5">
        <slot name="filters" />
      </div>

      <div class="max-lg:hidden">
        <slot name="sidebar" />
      </div>
    </aside>

    <article class="col-span-12 py-8 lg:col-span-9">
      <slot name="results" />
    </article>
  </div>
</template>

<script lang="ts">
export default { name: 'LayoutFacetedSearch' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useSearchStore } from '~/store/modules/search'
import { SearchType } from '~/components/search/enums/SearchType'

interface Props {
  isFilteredByCategory?: boolean
}

withDefaults(defineProps<Props>(), {
  isFilteredByCategory: false,
})

const searchStore = useSearchStore()

const { searchType } = storeToRefs(searchStore)

const url = computed(() => {
  switch (searchType.value) {
    case SearchType.PRODUCT:
      return { text: 'Search All Products', link: '/products' }
    case SearchType.BLOG:
      return { text: 'Back to All Blogs', link: '/blog' }
    default:
      return { text: 'Back to Search', link: '/search' }
  }
})
</script>
