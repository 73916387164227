<template>
  <svg>
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="spriteId" />
  </svg>
</template>

<script lang="ts">
export default { name: 'SvgIcon' }
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  iconName: string,
  title?: string,
  svgSpriteIdPrefix?: string,
}>(), {
  svgSpriteIdPrefix: 'sprite-',
  title: '',
})

const spriteId = computed(() => `#${props.svgSpriteIdPrefix}${props.iconName}`)
</script>
