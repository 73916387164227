import BaseHydratable from '~/hydrater/hydratables/BaseHydratable'
import { HydratableComponentFactory, HydrationStrategyOptions } from '~/hydrater/types'

/**
 * A Pattern for defining flexible Hydration Strategies
 *
 * A hydration strategy is a way to hydrate a component. For example, we have a generic
 * VueHydratable class that hydrates a Vue App, however we will likely want to customize
 * the app instance for different scenarios. We do this by creating a new named HydrationStrategy,
 * and passing it:
 *
 * - a HydratableComponentFactory function that will return a component instance that is customized for the scenario
 * - a Hydratable class that will be used to actually hydrate/dehydrate the component when required
 *
 */
export default class HydrationStrategy {
  public name: string
  factory: HydratableComponentFactory
  hydratable: BaseHydratable
  protected constructor({
    factory,
    name,
    hydratable,
  }: HydrationStrategyOptions) {
    this.factory = factory
    this.name = name
    this.hydratable = hydratable
  }

  /**
   * Start using a strategy on an element
   * @param el - the element to hydrate
   * @param data
   */
  public start(el: HTMLElement, data: Object): BaseHydratable {
    return new this.hydratable(this.factory, el, data)
  }
}
