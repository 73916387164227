<template>
  <div class="flex flex-row gap-5 md:flex-col group lg:col-span-3 motion-safe:animate-pulse">
    <div
      class="block md:hidden shrink-0"
    >
      <div class="bg-gray-200 h-[100px] w-[100px] shadow-white" />
    </div>
    <div class="relative shrink-0 aspect-w-1 aspect-h-1 max-md:hidden bg-gray-200" />
    <div class="flex flex-col gap-1 md:gap-2">
      <div class="h-[30px] w-3/4 bg-gray-300" />
      <div class="h-[30px] w-1/2 bg-gray-300" />
      <div class="h-[20px] w-full bg-gray-200" />
      <div class="h-[20px] w-3/4 bg-gray-200" />
    </div>
  </div>
</template>
