<template>
  <Disclosure
    v-slot="{ open }: { open: boolean }"
    as="div"
  >
    <DisclosureButton
      class="flex justify-between w-full text-sm font-semibold capitalize"
      data-test-id="category-list-trigger"
    >
      {{ heading }}

      <div class="lg:hidden">
        <SvgIcon
          aria-hidden="true"
          icon-name="chevron-down"
          class="w-5 h-4 shrink-0"
          :class="{
            'rotate-180': open,
          }"
        />
      </div>
    </DisclosureButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="mt-5">
        <slot />
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script lang="ts">
export default { name: 'CategoryListAccordion' }
</script>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

import SvgIcon from './SvgIcon.vue'

defineProps<{
  heading: string,
}>()
</script>
