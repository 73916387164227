<template>
  <render />
</template>
<script lang="ts">
export default {
  name: 'ConfigureStore',
}
</script>
<script setup lang="ts">
import { useUiStore } from '~/store/modules/ui'

const props = defineProps<{
  navWrapperClasses?: string,
}>()

const uiStore = useUiStore()

if (props.navWrapperClasses) {
  uiStore.setNavWrapperClasses(props.navWrapperClasses)
}
const render = () => null

</script>
