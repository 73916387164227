<template>
  <PopoverButton :as="as">
    <slot />
  </PopoverButton>
</template>

<script lang="ts">
export default { name: 'PopoverItem' }
</script>

<script setup lang="ts">
import { PopoverButton } from '@headlessui/vue'

withDefaults(
  defineProps<{
    as?: string
  }>(),
  {
    as: 'li',
  },
)
</script>
