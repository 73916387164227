import authComponents from './auth'
import cartComponents from './cart'
import globalComponents from './global'
import navigationComponents from './navigation'
import productComponents from './product'
import searchComponents from './search'

export default [
  ...authComponents,
  ...cartComponents,
  ...globalComponents,
  ...navigationComponents,
  ...productComponents,
  ...searchComponents,
]
