<template>
  <Popover
    as="li"
    class="md:relative"
  >
    <PopoverButton
      class="link link--blue-dark"
      data-test-id="search-bar-trigger"
      @click="handleClick"
      @keydown.enter="handleClick"
    >
      <span
        class="sr-only"
        v-text="'Search the Site'"
      />
      <SvgIcon
        aria-hidden="true"
        icon-name="search"
        class="w-5 h-5"
      />
    </PopoverButton>
    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        as="div"
        class="absolute border shadow-lg md:right-0 top-9 border-gray-blue-200 bg-gray-blue-50 p-3 flex gap-2.5 items-center w-11/12 max-md:top-16 max-md:right-3 md:w-96 z-40"
      >
        <div class="relative w-full">
          <SiteSearchDropdown
            :api-key="apiKey"
            :index-name="indexName"
            :app-id="appId"
          />
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script lang="ts">
export default { name: 'SearchBar' }
</script>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import SvgIcon from '../global/SvgIcon.vue'

import useFlyoutMenu from '~/composables/useFlyoutMenu'

defineProps<{
  indexName: string,
  appId: string,
  apiKey: string,
}>()

const SiteSearchDropdown = defineAsyncComponent(() =>
  import('~/components/search/SiteSearchDropdown.vue'),
)

const { toggleMenu } = useFlyoutMenu()

const handleClick = async (event: MouseEvent | KeyboardEvent) => {
  await toggleMenu(event, 'searchBar')
}
</script>
