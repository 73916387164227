import { defineStore } from 'pinia'

import User from '~/types/User'

interface UserState {
  user: User | null
  cartCount: number
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null as User | null,
    cartCount: 0,
  }),
  actions: {
    setUser(user: User | null) {
      this.user = user
    },
    setCartCount(count: number) {
      this.cartCount = count
    },
  },
})
