<template>
  <PopoverButton
    v-slot="{ open }: { open: boolean }"
    class="link link--blue-dark w-5"
    :aria-label="mobileNavIsOpen ? 'Close Site Navigation' : 'Open Site Navigation'"
    data-test-id="mobile-nav-toggle"
    @click="handleClick"
    @keydown.enter="handleClick"
  >
    <SvgIcon
      v-if="open"
      aria-hidden="true"
      icon-name="close"
      class="h-5 w-5"
    />
    <SvgIcon
      v-else
      aria-hidden="true"
      icon-name="menu"
      class="h-5 w-5"
    />
  </PopoverButton>
</template>

<script lang="ts">
export default { name: 'MobileNavToggle' }
</script>

<script setup lang="ts">
import { nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { PopoverButton } from '@headlessui/vue'

import SvgIcon from '../global/SvgIcon.vue'

import { useUiStore } from '~/store/modules/ui'

const uiStore = useUiStore()
const { mobileNavIsOpen } = storeToRefs(uiStore)

const handleClick = async (event: MouseEvent | KeyboardEvent) => {
  await nextTick()

  // Headless UI's Popover component displays a focus indicator on click.
  // This is a workaround to prevent that from happening.
  if (event instanceof MouseEvent && document.activeElement instanceof HTMLElement) {
    document.activeElement.blur()
  }

  uiStore.toggleMobileNav()
}

// TODO: Figure out how to remove focus indicator on click outside
// TODO: Figure out how to run custom logic on close
</script>
