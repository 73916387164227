<template>
  <div>
    <Splide
      ref="main"
      :options="mainOptions"
    >
      <slot />
    </Splide>

    <Splide
      ref="thumbs"
      :options="thumbsOptions"
      class="mt-5"
    >
      <slot name="tabs" />
    </Splide>
  </div>
</template>

<script lang="ts">
export default { name: 'ProductGallery' }
</script>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Splide, Options } from '@splidejs/vue-splide'

const main = ref<InstanceType<typeof Splide>>()
const thumbs = ref<InstanceType<typeof Splide>>()

const mainOptions: Options = {
  type: 'fade',
  rewind: true,
  pagination: false,
  arrows: false,
}

const thumbsOptions: Options = {
  type: 'slide',
  fixedWidth: 100,
  fixedHeight: 60,
  gap: 10,
  rewind: true,
  pagination: false,
  isNavigation: true,
  cover: true,
  focus: 'center',
  updateOnMove: true,
}

onMounted(() => {
  const thumbsSplide = thumbs.value?.splide

  if (thumbsSplide) {
    main.value?.sync(thumbsSplide)
  }
})
</script>
