import ProductGallery from './ProductGallery.vue'
import AddToCartStickyHeader from './AddToCartStickyHeader.vue'
import ProductGalleryImage from './ProductGalleryImage.vue'
import ProductGallerySelector from './ProductGallerySelector.vue'

const productComponents = [
  ProductGallery, 
  ProductGalleryImage,
  ProductGallerySelector,
  AddToCartStickyHeader,
]

export default productComponents
