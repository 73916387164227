import { nextTick } from 'vue'
import { POSITION, TYPE as NotificationType, useToast } from 'vue-toastification'

type NotifyOptions = {
  text: string
  position?: POSITION
  type?: NotificationType
  duration?: number | false
}

interface UseNotify {
  notify: (options: NotifyOptions) => Promise<void>
}

const useNotify = (): UseNotify => {
  const notify = async (options: NotifyOptions): Promise<void> => {
    const toast = useToast()

    toast(options.text, {
      timeout: options.duration,
      type: options.type || NotificationType.DEFAULT,
      position: options.position || POSITION.BOTTOM_RIGHT,
    })

    await nextTick()

    const notification = document.querySelector('.Vue-Toastification__close-button') as HTMLButtonElement

    if (notification) {
      notification.focus()
    }
  }

  return {
    notify,
  }
}

export default useNotify
