<template>
  <Splide
    :options="options"
    :has-track="false"
    class="col-span-12 testimonial-carousel"
  >
    <slot name="heading" />

    <SplideTrack class="pb-1">
      <slot />
    </SplideTrack>

    <div class="splide__arrows" />
  </Splide>
</template>

<script lang="ts">
export default { name: 'TestimonialCarousel' }
</script>

<script setup lang="ts">
import { Options } from '@splidejs/splide'
import { Splide, SplideTrack } from '@splidejs/vue-splide'

const options: Options = {
  type: 'slide',
  gap: '2rem',
  pagination: true,
  arrows: false,
  omitEnd: true,
  easing: 'ease',
  autoplay: true,
  perPage: 1,
  mediaQuery: 'min',
  breakpoints: {
    1025: {
      perPage: 3,
      arrows: true,
    },
    640: {
      perPage: 2,
      arrows: true,
    },
  },
}
</script>
