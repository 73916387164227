<template>
  <TransitionRoot
    :show="scrollTop > 200"
    enter="transition-opacity duration-200"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-200"
    leave-from="opacity-100"
    leave-to="opacity-0"
    data-test-id="product-sticky-header"
  >
    <slot />
  </TransitionRoot>
</template>

<script lang="ts">
export default { name: 'AddToCartStickyHeader' }
</script>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import throttle from 'lodash.throttle'

const scrollTop = ref(0)

const handleScroll = ()  => {
  scrollTop.value = window.scrollY
}

onBeforeMount(() => {
  window.addEventListener('scroll', throttle(handleScroll, 500, { leading: true }))
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
