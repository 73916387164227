<template>
  <!-- TODO: Handle active class -->
  <ul :class="listClasses">
    <template v-if="isLoggedIn">
      <li :class="listItemClasses">
        <a
          :href="accountSubdomain"
          v-text="'My Account'"
        />
      </li>
      <li :class="listItemClasses">
        <button
          type="button"
          data-test-id="logout-button"
          @click="handleLogout"
          v-text="'Log Out'"
        />
      </li>
    </template>
    <template v-else>
      <li :class="listItemClasses">
        <a
          :href="actionUrl('login')"
          v-text="'Log In'"
        />
      </li>
      <li :class="listItemClasses">
        <a
          :href="actionUrl('register')"
          v-text="'Sign Up'"
        />
      </li>
    </template>
    <li :class="listItemClasses">
      <a
        :href="urls.FRESHDESK"
        v-text="'Support'"
      />
    </li>
    <li class="max-lg:hidden flex">
      <CartLink />
    </li>
  </ul>
</template>

<script lang="ts">
export default { name: 'UtilityNavigation' }
</script>

<script setup lang="ts">
import axios from 'axios'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { TYPE } from 'vue-toastification'

import CartLink from './CartLink.vue'

import useNotify from '~/composables/useNotify'
import { useUserStore } from '~/store/modules/user'
import { useUiStore } from '~/store/modules/ui'

defineProps<{
  listClasses: string
  listItemClasses: string
}>()

const { notify } = useNotify()
const userStore = useUserStore()
const uiStore = useUiStore()

const { user } = storeToRefs(userStore)
const { route, urls } = storeToRefs(uiStore)

const isLoggedIn = computed(() => !!user.value)

const accountSubdomain = computed(() => import.meta.env.VITE_ACCOUNT_SUBDOMAIN)

const actionUrl = (action: string) => `${accountSubdomain.value}/${action}?continue=${window.location.origin}${route.value}`

const handleLogout = async () => {
  try {
    await axios.post(`${import.meta.env.VITE_ACCOUNT_SUBDOMAIN}/api/logout`, {}, {
      withCredentials: true,
      withXSRFToken: true,
    })

    userStore.setUser(null)
    userStore.setCartCount(0)
  } catch (error) {
    notify({
      duration: false,
      type: TYPE.ERROR,
      text: `An error occurred and you couldn't be logged out.`,
    })
  }
}
</script>
