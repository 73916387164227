<template>
  <SplideSlide class="aspect-w-1 aspect-h-1">
    <slot />
  </SplideSlide>
</template>

<script lang="ts">
export default { name: 'ProductGalleryImage' }
</script>

<script setup lang="ts">
import { SplideSlide } from '@splidejs/vue-splide'
</script>
