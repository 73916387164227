<template>
  <Popover
    as="li"
    class="lg:hidden"
  >
    <MobileNavToggle />
    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        as="nav"
        aria-label="Main Navigation"
        class="absolute top-full left-0 z-40 right-0 px-4 pt-6 overflow-hidden bg-white shadow-lg mt-30 pb-9"
        @close="uiStore.resetMobileNav"
      >
        <slot />
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script lang="ts">
export default { name: 'MobileNavigation' }
</script>

<script setup lang="ts">
import { watch } from 'vue'
import { useWindowSize } from 'vue-window-size'
import { Popover, PopoverPanel } from '@headlessui/vue'

import MobileNavToggle from './MobileNavToggle.vue'

import { useUiStore } from '~/store/modules/ui'

const uiStore = useUiStore()

const { width } = useWindowSize()

watch(
  width,
  () => {
    if (width.value >= 1024) {
      uiStore.resetMobileNav()
    }
  },
)
</script>
