import * as Sentry from '@sentry/vue'
import SentryConfig from '~/types/SentryConfig'
import { Vue } from '@sentry/vue/types/types'

export default (CONFIG: SentryConfig, app: Vue): void => {
  if (!CONFIG.ENABLED) {
    return
  }
  Sentry.init({
    app,
    dsn: CONFIG.DSN,
    environment: `js_${CONFIG.ENVIRONMENT}`,
    release: CONFIG?.RELEASE ?? undefined,
    trackComponents: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Only capture errors from CC domains
    allowUrls: [
      /https?:\/\/(.*)?(\.*)comprehensibleclassroom?(\.)(\w|\.|-)*/,
      /https?:\/\/(.*)?(\.*)client-testing?(\.)(\w|\.|-)*/,
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // These promise rejection events are not errors, and are caused by MS Outlook's "safe links" feature
      // @see #532, https://github.com/getsentry/sentry-javascript/issues/3440
      'Non-Error promise rejection captured',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}
