<template>
  <div />
</template>

<script lang="ts">
export default { name: 'AuthChecker' }
</script>

<script setup lang="ts">
import axios from 'axios'
import { VueCookies } from 'vue-cookies'
import { inject, onBeforeUnmount, onMounted, ref } from 'vue'

import { useUserStore } from '~/store/modules/user'

const $cookies = inject<VueCookies>('$cookies')

const userStore = useUserStore()

const pollingInterval = ref<number | undefined>(undefined)

onMounted(async (): Promise<void> => {
  await getUser()

  pollAuth()
})

onBeforeUnmount(() => {
  clearPoll()
})

const getUser = async (): Promise<void> => {
  try {
    let config = {
      withCredentials: true,
    }

    if (import.meta.env.VITE_ENABLE_BASIC_AUTH === 'enabled') {
      config['headers'] = {
        Authorization: 'Basic Y2M6emFlbmdsZQ==',
      }
    }

    const response = await axios.get(`${import.meta.env.VITE_ACCOUNT_SUBDOMAIN}/api/user?include=cart_count`, config)

    userStore.setCartCount(response?.data?.cart_count)

    delete response?.data?.cart_count

    userStore.setUser(response?.data)
  } catch (error) {
    userStore.setUser(null)

    if (error?.response?.status === 401) {
      await getCart()
    }
  }
}

const pollAuth = () => {
  pollingInterval.value = window.setInterval(async (): Promise<void> => {
    await getUser()
  }, import.meta.env.VITE_AUTH_POLLING_INTERVAL)
}

const clearPoll = () => {
  window.clearInterval(pollingInterval.value)
}

const getCart = async (): Promise<void> => {
  if (!$cookies) {
    return
  }

  const cartId = $cookies.get('cartId')

  if (cartId) {
    try {
      let config = {}

      if (import.meta.env.VITE_ENABLE_BASIC_AUTH === 'enabled') {
        config['headers'] = {
          Authorization: 'Basic Y2M6emFlbmdsZQ==',
        }
      }

      const response = await axios.get(`${import.meta.env.VITE_ACCOUNT_SUBDOMAIN}/api/cart/${cartId}`, config)

      userStore.setCartCount(response?.data?.data?.cart_count)
    } catch (error) {
      if (error?.response?.status === 404) {
        $cookies.remove('cartId', '/', import.meta.env.VITE_COOKIE_DOMAIN)
      }
    }
  }
}
</script>
