<script setup lang="ts">
import { inject, ref } from 'vue'
import axios from 'axios'
import useNotify from '~/composables/useNotify'

import SvgIcon from '../global/SvgIcon.vue'

import { TYPE } from 'vue-toastification'
import { VueCookies } from 'vue-cookies'

const $cookies = inject<VueCookies>('$cookies')
const { notify } = useNotify()

import { useUserStore } from '~/store/modules/user'

const userStore = useUserStore()

const props = defineProps<{ id: string, btnClass: string }>()

const loading = ref(false)
const addedToCart = ref(false)
const btnSizes = props.btnClass ? props.btnClass : ' btn--md md:btn--lg lg:btn--xl'
let cartUrl = import.meta.env.VITE_ACCOUNT_SUBDOMAIN + '/cart'

const addToCart = async () => {
  if (!$cookies) {
    return
  }

  try {
    loading.value = true

    let config = {
      withCredentials: true,
      withXSRFToken: true,
    }

    if (import.meta.env.VITE_ENABLE_BASIC_AUTH === 'enabled') {
      config['headers'] = {
        Authorization: 'Basic Y2M6emFlbmdsZQ==',
      }
    }

    const response = await axios.post(`${import.meta.env.VITE_ACCOUNT_SUBDOMAIN}/api/cart/products/add/${props.id}`, {
      id: $cookies.get('cartId'),
    }, config)

    $cookies.set(
      'cartId',
      response?.data?.data?.id,
      Infinity,
      undefined,
      import.meta.env.VITE_COOKIE_DOMAIN,
      false,
    )

    userStore.setCartCount(response?.data?.data?.cart_count)

    await notify({
      duration: 5000,
      type: TYPE.SUCCESS,
      text: `Added to your cart!`,
    })
  }  catch (error) {
    await notify({
      duration: false,
      type: TYPE.ERROR,
      text: `An error occurred while adding this item.`,
    })
  } finally {
    loading.value = false
    addedToCart.value = true
  }
}
</script>
<script lang="ts">
export default { name: 'AddToCartButton' }
</script>
<template>
  <button
    v-if="!addedToCart"
    type="button"
    class="md:min-w-[250px] btn btn--orange"
    :class="btnSizes"
    :aria-disabled="loading"
    :disabled="loading"
    @click="addToCart"
    v-text="loading ? 'Adding...' : 'Add to Cart'"
  />

  <a
    v-if="addedToCart"
    class="md:min-w-[250px] inline-flex btn btn--primary  text-center"
    :class="btnSizes"
    :href="cartUrl"
  >
    Go Checkout
    <SvgIcon
      aria-hidden="true"
      icon-name="arrow-right"
      class="w-5 h-5 shrink-0"
    />
  </a>
</template>
