import BaseHydratable from '~/hydrater/hydratables/BaseHydratable'

/**
 * A Hydratable for Vue components
 */
export default class VueHydratable extends BaseHydratable {
  mount() {
    this.component.mount(this.el)
    return this
  }
  unmount() {
    this.component.unmount(this.el)
    return this
  }
}
