/**
 * Search types
 *
 * Must be synced with the backend:
 * @see src/modules/algolia/enums/SearchType.php
 */
export enum SearchType {
  PRODUCT = 'product' ,
  SITE = 'site' ,
  BLOG = 'blog' ,
}
