<template>
  <SplideSlide>
    <slot />
  </SplideSlide>
</template>

<script lang="ts">
export default { name: 'TestimonialSlide' }
</script>

<script setup lang="ts">
import { SplideSlide } from '@splidejs/vue-splide'
</script>
