import HydrationStrategy from '~/hydrater/base/HydrationStrategy'
import BaseHydratable from '~/hydrater/hydratables/BaseHydratable'
import { HydraterOptions } from '~/hydrater/types'

/**
 * The Hydrater is responsible for finding elements in the DOM that need to be hydrated into JS Components
 *
 * It does not directly implement hydration of elements, instead it  implements the strategy pattern.
 * Actual hydration is delegated to one or more HydrationStrategies that are passed to the Hydrater on instantiation.
 *
 * Selection of the correct strategy is done by mapping the value of the data-hydrate attribute on the element to be hydrated
 * to the name field of a HydrationStrategy.
 *
 * This class is responsible for:
 *
 * - Finding elements in the DOM that need to be hydrated
 * - Finding the correct HydrationStrategy to use for a given element
 * - Mounting a Hydratable onto an element using a HydrationStrategy
 * - Calling the Hydratable's .unmount() method when an element is removed from the DOM
 */
export default class Hydrater {
  public selector: string
  public strategies: HydrationStrategy[]
  private $_mounts: Map<HTMLElement, BaseHydratable>

  constructor(
    strategies: HydrationStrategy[] = [],
    options: HydraterOptions = {},
  ) {
    this.$_mounts = new Map()
    this.strategies = strategies
    this.selector = options.selector ?? '[data-hydrate]'
  }

  /**
   * Look for changes and update as required
   */
  public update(): void {
    this.gc()
    this.build()
  }

  /**
   * Clean up any mounts that no longer exist in the DOM
   */
  public gc(): void {
    this.mounts.forEach((hydratable, el) => {
      if (!document.body.contains(el)) {
        this.deleteHydratable(el)
      }
    })
  }

  /**
   * Remove a Hydratable from the DOM
   * @param el
   */
  public dehydrate(el: HTMLElement): void {
    this.deleteHydratable(el)
  }

  /**
   * Find new mount points in the DOM and mount Hydratables on them
   */
  public build(): void {
    const selectors = [...document.querySelectorAll(this.selector)] as HTMLElement[]

    selectors.forEach((el: HTMLElement) => {
      // Don't hydrate if we already have a mount for this element
      if (this.getHydratable(el)) {
        return
      }

      const hydratable = this.hydrate(el)

      if (!hydratable) {
        return
      }

      this.setHydratable(el, hydratable)
    })
  }

  /**
   * Mount a Hydratable onto an element using a Strategy
   * @param el
   */
  public hydrate(el: HTMLElement): BaseHydratable | null {
    // @todo currently this will break if this.selector has been changed from the default
    const {
      hydrate: handle,
    } = el.dataset as DOMStringMap & { hydrate: string }

    if (!handle) {
      console.error(`No strategy defined for ${el}`)

      return null
    }

    const strategy: HydrationStrategy | null = this.getStrategy(handle)

    if (!strategy) {
      console.error(`No strategy defined for ${handle}`)

      return null
    }

    return strategy.start(el, { ...el.dataset }).mount()
  }

  /**
   * Determine a mounting strategy from a handle
   * @param name
   */
  public getStrategy(name: string | undefined): HydrationStrategy | null {
    return this.strategies.find(strategy => strategy.name === name) ?? null
  }

  protected get mounts(): Map<HTMLElement, BaseHydratable> {
    return this.$_mounts
  }

  protected getHydratable(el: HTMLElement): BaseHydratable | null {
    return this.$_mounts.get(el) ?? null
  }

  protected setHydratable(el: HTMLElement, hydratable: BaseHydratable): void {
    this.$_mounts.set(el, hydratable)
  }

  protected deleteHydratable(el: HTMLElement): void {
    const hydratable = this.getHydratable(el)

    if (hydratable) {
      hydratable.unmount()

      this.$_mounts.delete(el)
    }
  }
}
