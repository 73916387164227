import { defineStore } from 'pinia'
import { getCurrentUrl } from 'swup'

export const useUiStore = defineStore('ui', {
  state: () => ({
    route: getCurrentUrl(),
    urls: {} as Record<string, string>,
    openNavDropdown: '',
    mobileNavIsOpen: false,
    cartCount: 0,
    quickViewModal: '',
    _navWrapperClasses: 'bg-wave-gradient',
    isPageTransitioning: false,
  }),
  actions: {
    toggleNavDropdown(dropdownName: string | undefined = undefined) {
      this.openNavDropdown = dropdownName
    },
    toggleMobileNav() {
      this.mobileNavIsOpen = !this.mobileNavIsOpen
    },
    resetMobileNav() {
      this.mobileNavIsOpen = false
    },
    setCartCount(count: number) {
      this.cartCount = count
    },
    toggleQuickViewModal(productUrl: string) {
      this.quickViewModal = productUrl
    },
    setNavWrapperClasses(classes: string) {
      this._navWrapperClasses = classes
    },
    startPageTransition() {
      this.isPageTransitioning = true
    },
    endPageTransition() {
      this.isPageTransitioning = false
    },
    setRoute() {
      this.route = getCurrentUrl()
    },
    setUrls(urls: Record<string, string>) {
      this.urls = urls
    }
  },
  getters: {
    navWrapperClasses() {
      if (this.isPageTransitioning) {
        return 'bg-wave-gradient'
      }
      return this._navWrapperClasses
    }
  }
})
