import AccordionItem from './AccordionItem.vue'
import CategoryListAccordion from './CategoryListAccordion.vue'
import PopoverItem from './PopoverItem.vue'
import ConfigureStore from './ConfigureStore.vue'
import SvgIcon from './SvgIcon.vue'
import TestimonialCarousel from './TestimonialCarousel.vue'
import TestimonialSlide from './TestimonialSlide.vue'

const globalComponents = [
  AccordionItem,
  CategoryListAccordion,
  PopoverItem,
  ConfigureStore,
  SvgIcon,
  TestimonialCarousel,
  TestimonialSlide,
]

export default globalComponents
