<template>
  <SiteSearch
    :api-key="apiKey"
    :index-name="indexName"
    :index-prefix="indexPrefix"
    :app-id="appId"
    :enc-dict="encDict"
    :enc-hidden-facets="encHiddenFacets"
    :filters="filters"
    :search-type="searchType"
    :enc-initial-ui-state="encInitialUiState"
    :hits-style="hitsStyle"
  >
    <template #sidebar>
      <slot name="sidebar" />
    </template>
  </SiteSearch>
</template>

<script lang="ts">
export default { name: 'SearchWrapper' }
</script>

<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue'

import SearchLoading from './partials/SearchLoading.vue'
import SearchError from './partials/SearchError.vue'

import { SearchType } from '~/components/search/enums/SearchType'

import {
  HitType
} from '~/components/search/enums/HitType'

const props = defineProps<{
  indexName: string,
  indexPrefix: string,
  appId: string,
  apiKey: string,
  encDict: string,
  encHiddenFacets?: string,
  encInitialUiState?: string,
  searchType: SearchType,
  hitsStyle?: string
}>()

const SiteSearch = defineAsyncComponent({
  loader: async () => {
    return import('~/components/search/SiteSearch.vue')
  },
  delay: 0,
  loadingComponent: SearchLoading,
  errorComponent: SearchError,
})

const filters = computed(() => {
  switch (props.searchType) {
    case SearchType.PRODUCT:
      return `hitType:${HitType.PRODUCT} OR hitType:${HitType.BUNDLE}`
    case SearchType.BLOG:
      return `hitType:${HitType.BLOG}`
    default:
      return ''
  }
})


</script>
