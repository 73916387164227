<template>
  <Popover as="div">
    <DesktopFlyoutMenuTrigger :text="buttonText" />
    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        as="div"
        :class="panelClasses"
      >
        <slot />
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script lang="ts">
export default { name: 'FlyoutMenu' }
</script>

<script setup lang="ts">
import { Popover, PopoverPanel } from '@headlessui/vue'

import DesktopFlyoutMenuTrigger from './DesktopFlyoutMenuTrigger.vue'

defineProps<{
  buttonText: string
  panelClasses: string
}>()
</script>
