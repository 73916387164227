import CartLink from './CartLink.vue'
import SearchBar from './SearchBar.vue'
import FlyoutMenu from './FlyoutMenu.vue'
import MobileNavToggle from './MobileNavToggle.vue'
import MobileNavigation from './MobileNavigation.vue'
import UtilityNavigation from './UtilityNavigation.vue'
import DesktopFlyoutMenuTrigger from './DesktopFlyoutMenuTrigger.vue'

const navigationComponents = [
  CartLink,
  SearchBar,
  FlyoutMenu,
  MobileNavToggle,
  MobileNavigation,
  UtilityNavigation,
  DesktopFlyoutMenuTrigger,
]

export default navigationComponents
